import { useCallback, ChangeEvent, FocusEvent } from 'react';
import { FormikErrors, FormikTouched } from 'formik';
import { Box, Button, Grid, Stack, TextField } from '@mui/material';
import { WidgetBgPatterns, IWidgetSettings } from '../../models/ICompany';
import BgPatternBox from './BgPatternBox';
import { useTheme } from '@material-ui/core/styles';
import InputRow from '../../ui-component/form/InputRow';
import ColorPicker from '../../ui-component/color-picker';

interface WidgetStyleSettingsProps {
    values: IWidgetSettings;
    touched: FormikTouched<IWidgetSettings>;
    errors: FormikErrors<IWidgetSettings>;
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleBlur: (e: FocusEvent<HTMLInputElement>) => void;
    setFieldValue: (field: string, value: any) => void;
}

const WidgetStyleSettings = ({ values, handleChange, touched, errors, setFieldValue, handleBlur }: WidgetStyleSettingsProps) => {
    const theme = useTheme();

    const resetDefaults = useCallback(() => {
        setFieldValue('primaryColor', theme.palette.widget.green);
        setFieldValue('textColor', theme.palette.widget.text);
        setFieldValue('buttonColor', theme.palette.widget.buttonDetails);
        setFieldValue('bgPattern', undefined);
        setFieldValue('widgetBgPattern', undefined);
    }, [setFieldValue, theme.palette.widget]);

    return (
        <Stack spacing={3} sx={{ maxWidth: '100%', width: '600px' }}>
            <InputRow label="Custom CSS" forceColumn size="sm">
                <TextField
                    multiline
                    fullWidth
                    rows={4}
                    id="custom_css"
                    name="custom_css"
                    value={values.custom_css ?? ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    placeholder="Customize your CSS"
                    InputProps={{
                        className: 'input-blue'
                    }}
                />
            </InputRow>

            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                        <InputRow label="Primary Color" info="Primary color used in booking widget." size="sm" forceColumn>
                            <ColorPicker
                                label="Color"
                                id="primaryColor"
                                name="primaryColor"
                                value={values?.primaryColor ?? theme.palette.widget.green}
                                onChange={(v) => setFieldValue('primaryColor', v)}
                                fullWidth
                                error={Boolean(touched?.primaryColor && errors?.primaryColor)}
                                helperText={touched?.primaryColor ? errors?.primaryColor : undefined}
                            />
                        </InputRow>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <InputRow label="Text Color" info="Text color used in booking widget." forceColumn size="sm">
                            <ColorPicker
                                label="Color"
                                id="textColor"
                                name="textColor"
                                value={values?.textColor ?? theme.palette.widget.text}
                                onChange={(v) => setFieldValue('textColor', v)}
                                fullWidth
                                error={Boolean(touched?.textColor && errors?.textColor)}
                                helperText={touched?.textColor ? errors?.textColor : undefined}
                            />
                        </InputRow>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <InputRow label="Button Color" info="Button color used in booking widget." forceColumn size="sm">
                            <ColorPicker
                                label="Color"
                                fullWidth
                                error={Boolean(touched?.buttonColor && errors?.buttonColor)}
                                helperText={touched?.buttonColor ? errors?.buttonColor : undefined}
                                id="buttonColor"
                                name="buttonColor"
                                value={values?.buttonColor ?? theme.palette.widget.buttonDetails}
                                onChange={(v) => setFieldValue('buttonColor', v)}
                            />
                        </InputRow>
                    </Grid>

                    <Grid item xs={12}>
                        <InputRow
                            label="Widget Background Pattern"
                            info="Background pattern used in booking widget. Please contact support if you need color schemes that are not currently present."
                            forceColumn
                            size="sm"
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '8px' }}>
                                {WidgetBgPatterns.map((pattern, key) => (
                                    <BgPatternBox
                                        key={key}
                                        isActive={values?.bgPattern === key}
                                        start={pattern.start}
                                        end={pattern.end}
                                        onClick={() => {
                                            setFieldValue('bgPattern', key);
                                            setFieldValue('widgetBgPattern', WidgetBgPatterns[key]);
                                        }}
                                    />
                                ))}
                            </Box>
                        </InputRow>
                    </Grid>

                    <Grid item xs={12}>
                        <Button className="forcedBg" onClick={resetDefaults}>
                            Reset to Default Settings
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Stack>
    );
};

export default WidgetStyleSettings;
